export interface AppRolePermissionType {
  userLoginId: string;
  appTypeText: string;
  appTypeId: number;
  roleName: string;
  roleId: number;
  isPermissionLevelActive: boolean;
  isRoleActive: boolean;
  permissionLevelName: string;
  permissionLevelId: number;
}

interface Map {
  [key: number]: AppRolePermissionType;
}

export type GroupedKeys =
  | "ADMIN"
  | "DOCUMENTATION"
  | "REPORTING"
  | "DELEGATED"
  | "CASH_TRACKER"
  | "COMMON";

export type OutputAllowedApps = Partial<Record<GroupedKeys, AllowedApps[]>>;

export interface LandingPageAppsType {
  title: string;
  detail: string;
  icon: JSX.Element;
  to?: string;
  name?: string;
  apptypeID: number;
}
export interface AllowedApps {
  to: string;
  pathNameForTabs: string;
  icon: JSX.Element;
  primary: string;
  name: string;
  apptypeID: number;
  comp: JSX.Element;
  groupby: GroupedKeys;
  topNav?: boolean;
  groupTabName: string;
}

export const RolesAcessLookUp = {
  SystemAdministrator: 1,
  TenantAdministrator: 2,
  ClientLocationAdministrator: 3,
  StandardUser: 4,
  BrokerBillingInternal: 5,
  BrokerBillingExternalContributor: 6,
  BrokerBillingExternalViewer: 7,
  LimitedInternalUserAdmin: 8,
} as const;

export const PermissionLevelLookup = {
  Admin: 1,
  Contributor: 2,
  Viewer: 3,
};

export const UserAppsLookup = {
  SystemAdministration: 1,
  LossReporting: 2,
  BrokerBilling: 3,
  UserAdministration: 4,
  RoleAdministration: 5,
  AppRolePermissionAdministration: 6,
  ClientLocationAppAdministration: 7,
  ClientLocationDomainAdministration: 8,
  RoleappAdministration: 9,
  Documentation: 10,
  UnderwritingGuide: 12,
  SupplementalApplications: 13,
  OracleDocucorpLibraries: 14,
  Applications: 15,
  ActionLogType: 16,
  ActionLogDashboard: 17,
  DataSyncLog: 18,
  ClientPortal: 19,
  DelegatedExperienceReport: 20,
  DelegatedIncurredLossReport: 21,
  ReferenceTables: 22,
  DelegatedIssues: 23,
  CashLog: 24,
  CashTracker: 25,
  UserReporting: 26,
} as const;

export function checkForRoleAccess(
  value: number[],
  name: string,
  obj: typeof RolesAcessLookUp = RolesAcessLookUp
) {
  const found = Object?.keys(obj).find(
    (key: any) => obj[key as keyof typeof obj] === value[0]
  );

  if (found && found === name) {
    return true;
  } else return false;
}

export const AdminAppTypeAccessLookUp = {
  UserAdministration: 4,
  RoleAdministration: 5,
  AppRolePermissionAdministration: 6,
  ClientLocationAppAdministration: 7,
  ClientLocationDomainAdministration: 8,
  RoleappAdministration: 9,
  Documentation: 10,
  ReferenceTables: 22,
  UserReporting: 26,
} as const;

export const CommonAppTypeAccessLookUp = {
  SystemAdministration: 1,
  LossReporting: 2,
  BrokerBilling: 3,
  UnderwritingGuide: 12,
  SupplementalApplications: 13,
  OracleDocucorpLibraries: 14,
  Applications: 15,
  DataSync: 18,
  DelegatedIssues: 23,
} as const;

export const ReportingAppTypeAccessLookUp = {
  ActionLogType: 16,
  ActionLogDashboard: 17,
} as const;

export const DelegatedAccessLookUp = {
  DelegatedExperienceReport: 20,
  DelegatedIncurredLossReport: 21,
} as const;

export const CashTrackerLookup = {
  CashLog: 24,
  CashTracker: 25,
} as const;

export const DocumentationAccessLookUp = {
  UnderwritingGuide: 12,
  SupplementalApplications: 13,
  OracleDocucorpLibraries: 14,
  Applications: 15,
} as const;

export const CashTrackerRoles = {
  CASH_TRACKER_QA_OPERATIONS: 9,
  CASH_TRACKER_ADMIN: 10,
  CASH_TRACKER_QA_CONTRIBUTER: 11,
  CASH_TRACKER_QA_VIEWER: 12,
  CASH_TRACKER_QC_CONTRIBUTER: 13,
  CASH_TRACKER_QC_VIEWER: 14,
  CASH_TRACKER_CONTRIBUTER: 15,
  CASH_TRACKER_VIEWER: 16,
} as const;

const objectSorter = <T extends OutputAllowedApps>(GFG_Object: T): T => {
  const sortedKeys = Object.keys(GFG_Object).sort();
  const sortedObject: T = {} as T;

  sortedKeys.forEach((key) => {
    sortedObject[key as keyof T] = GFG_Object[key as keyof T];
  });

  return sortedObject;
};

const parseAppRolePermissions = (appRolePermissions: string) =>
  JSON.parse(appRolePermissions);

const createAppRoleMap = (
  parsedAppRolePermissions: AppRolePermissionType[],
  isExternalUser: boolean
) => {
  const adminCheckEnum = Object.values(AdminAppTypeAccessLookUp).filter(
    (app) => app !== 4
  ) as number[];

  const parsedAppRoleMap = parsedAppRolePermissions.reduce(
    (acc: Map, currentValue: AppRolePermissionType) => {
      if (!acc[currentValue.appTypeId]) {
        // ADMIN CANT BE ASSIGNED APPS IF EXTERNAL
        if (isExternalUser && adminCheckEnum.includes(currentValue.appTypeId))
          return acc;

        acc[currentValue.appTypeId] = currentValue;
      }
      return acc;
    },
    {}
  );
  return parsedAppRoleMap;
};

const filteredAllowedApps = <T extends { apptypeID: number }>(
  enumApps: T[],
  appRoleMap: Map
) => {
  const allowedApps = enumApps.filter((item) => appRoleMap[item.apptypeID!]);
  return allowedApps as T[];
};

function groupBy<T>(collection: T[], key: keyof T) {
  const groupedResult = collection.reduce((previous, current) => {
    if (!previous[current[key]]) {
      previous[current[key]] = [] as T[];
    }

    previous[current[key]].push(current);
    return previous;
  }, {} as any);
  return groupedResult;
}

export const landingPageTilesSorter = (
  a: LandingPageAppsType,
  b: LandingPageAppsType
) => {
  let fa = a.title.replace(/ +/g, "").toLowerCase(),
    fb = b.title.replace(/ +/g, "").toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export const checkAppAccess = (
  enumApps: AllowedApps[],
  isExternalUser: boolean = false,
  appRolePermissions?: string
) => {
  if (!appRolePermissions) return {};

  const parsedAppRolePermissions = parseAppRolePermissions(appRolePermissions);

  const appRoleMap = createAppRoleMap(parsedAppRolePermissions, isExternalUser);

  const allowedApps = filteredAllowedApps<AllowedApps>(enumApps, appRoleMap);

  const groupedApps = groupBy(allowedApps as AllowedApps[], "groupby");
  const sortedObj = objectSorter(groupedApps);
  return sortedObj;
};

export const checkLandingPageTilesAccess = (
  enumApps: LandingPageAppsType[],
  isExternalUser: boolean = false,
  appRolePermissions?: string
) => {
  if (!appRolePermissions) return [];

  const parsedAppRolePermissions = parseAppRolePermissions(appRolePermissions);

  const appRoleMap = createAppRoleMap(parsedAppRolePermissions, isExternalUser);

  const allowedApps = filteredAllowedApps<LandingPageAppsType>(
    enumApps,
    appRoleMap
  );

  return allowedApps;
};
