import React, { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { commonAxios } from "../../../../services/axios";
import { AgGridReact as AgGridReactType } from "ag-grid-react/lib/agGridReact";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { CustomSnackbar, handleErrorResponse, ModalErrors } from "@genrecp/g2clientportal-common";
import ClientLocations from "./ClientLocations";
import AppRoles from "./AppRoles";

export interface User {
  userID: string;
  userLoginID: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  managerID: string | null;
  businessPhone: string;
  external: boolean;
  active: boolean;
  isManagedByG2: boolean;
}

const defaultColDef: ColDef = {
  cellClass: "cell-wrap-text",
  cellStyle: { whiteSpace: "normal" },
  sortable: true,
  resizable: true,
  floatingFilter: true,
  flex: 1,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
};

const UserGrid: React.FC = () => {
  const gridRef = useRef<AgGridReactType>(null);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const columnDefs = [
    {
      headerName: "Login ID",
      field: "userLoginID",
      flex: 2,
      filter: "agTextColumnFilter",
      tooltipField: "userLoginID",
    },
    {
      headerName: "First Name",
      field: "firstName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Last Name",
      field: "lastName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "emailAddress",
      flex: 2,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Business Phone",
      field: "businessPhone",
      filter: "agTextColumnFilter",
      flex:1.2,
    },
    {
      headerName: "Active",
      field: "active",
      flex: 0.8,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        suppressAndOrCondition: true,
      },
    },
    {
      cellRenderer: "ClientLocations",
      sortable:false,
    },
    {
      cellRenderer: "AppRoles",
      flex: 0.8,
      sortable:false
    },
  ];

  const onGridReady = (params: GridReadyEvent) => {
    commonAxios
      .get("/UserReporting")
      .then((res) => {
        params.api.setRowData(res.data || []);
      })
      .catch((e: any) => {
        params.api.setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const frameworkComponents = {
    ClientLocations: ClientLocations,
    AppRoles: AppRoles
  };

  return (
    <Grid container spacing={1}>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid xs={12} sm={12} md={12} item>
        <Typography variant="displaySmall">User Reporting</Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12} item>
      <div
        style={{ width: "100%", height: window.innerHeight - 120 }}
        className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
      >
        <AgGridReact
          rowHeight={29}
          headerHeight={32}
          ref={gridRef}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          pagination={true}
          cacheBlockSize={20}
          paginationPageSize={20}
          animateRows={true}
          components={frameworkComponents}
          tooltipMouseTrack={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
      </Grid>
    </Grid>
  );
};

export default UserGrid;
