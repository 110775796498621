import React, { Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ICellRendererParams, ColDef, GridReadyEvent } from "ag-grid-community";
import { commonAxios } from "../../../../services/axios";
import {
  CustomDrawer,
  CustomSnackbar,
  DetailSpan,
  handleErrorResponse,
  ModalErrors,
} from "@genrecp/g2clientportal-common";
import { Grid, Typography } from "@mui/material";
import { User } from "./UserReporting";
interface ClientLocations {
  userID: string;
  clientLocationID: string;
  clientGroupID: string;
  businessName: string;
  mailAddress1: string;
  mailAddress2: string;
  mailCity: string;
  mailState: string;
  mailZip: string;
  phone1: string;
}

interface AppTypeRoles {
  userID: string;
  appTypeName: string;
  roleName: string;
}

const defaultColDef: ColDef = {
  cellClass: "cell-wrap-text",
  cellStyle: { whiteSpace: "normal" },
  sortable: true,
  resizable: true,
  floatingFilter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
};

function ClientLocations(props: ICellRendererParams<User>) {
  const [open, setOpen] = React.useState(false);
  const userID = props.data?.userID;
  const onClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const clientLocationColumnDefs = [
    {
      field: "clientGroupID",
      colId: "clientGroupID",
      headerName: "Client Group ID",
      filter: "agTextColumnFilter",
    },
    {
      field: "clientLocationID",
      colId: "clientLocationID",
      headerName: "Client LocationID",
      filter: "agTextColumnFilter",
      minWidth:160
    },
    {
      field: "businessName",
      colId: "businessName",
      headerName: "Business Name",
      filter: "agTextColumnFilter",
      tooltipField: "businessName",
    },
    {
      field: "mailAddress1",
      colId: "mailAddress1",
      headerName: "Mail Address1",
      filter: "agTextColumnFilter",
      tooltipField: "mailAddress1",
    },
    {
      field: "mailAddress2",
      colId: "mailAddress2",
      headerName: "Mail Address2",
      filter: "agTextColumnFilter",
      tooltipField: "mailAddress2",
    },
    {
      field: "mailCity",
      colId: "mailCity",
      headerName: "Mail City",
      filter: "agTextColumnFilter",
      tooltipField: "mailCity",
    },
    {
      field: "mailState",
      colId: "mailState",
      headerName: "Mail State",
      filter: "agTextColumnFilter",
      tooltipField: "mailState",
    },
    {
      field: "mailZip",
      colId: "mailZip",
      headerName: "Mail Zip",
      filter: "agTextColumnFilter",
    },
    {
      field: "phone1",
      colId: "phone1",
      headerName: "Phone Number",
      filter: "agTextColumnFilter",
    },
  ];

  const getData = (params: GridReadyEvent<AppTypeRoles>) => {
    params.api.sizeColumnsToFit();
    commonAxios
      .get(`UserReporting/GetUserData/${userID}/${props.data?.external}`)
      .then((res) => {
        const { clientLocations } = res?.data || {};
        params.api.setRowData(clientLocations || []);
      })
      .catch((e) => {
        params.api.setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const onGridReady = (params: GridReadyEvent) => {
    if (userID) getData(params);
  };

  if (!props.data?.external) return null;

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <DetailSpan onClick={handleClickOpen}>Client Locations</DetailSpan>
      <CustomDrawer
        open={open}
        title="Client Locations"
        onClose={onClose}
        drawerWidth={window.innerWidth - 100}
        setOpen={setOpen}
        drawerPadding={0}
      >
        <Grid container spacing={2}>
          <Grid
            item
            md={4}
            mb={2}
            display={"flex"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <Typography variant="labelMedium">Login ID:</Typography>
            <Typography variant="titleMedium">
              {props.data?.userLoginID}
            </Typography>
          </Grid>
          <Grid item md={4} display={"flex"} justifyContent={"center"} gap={1}>
            <Typography variant="labelMedium">Name: </Typography>
            <Typography variant="titleMedium">
              {`${props.data?.firstName} ${props.data?.lastName}`}
            </Typography>
          </Grid>
          <Grid item md={4} display={"flex"} justifyContent={"end"} gap={1}>
            <Typography variant="labelMedium">Email: </Typography>
            <Typography variant="titleMedium">
              {props.data?.emailAddress}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <div
              style={{ width: "100%", height: window.innerHeight - 250 }}
              className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
            >
              <AgGridReact
                rowHeight={29}
                headerHeight={32}
                defaultColDef={defaultColDef}
                columnDefs={clientLocationColumnDefs}
                pagination={true}
                cacheBlockSize={10}
                onGridReady={onGridReady}
                paginationPageSize={10}
                animateRows={true}
                tooltipMouseTrack={true}
                tooltipShowDelay={0}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </CustomDrawer>
    </Fragment>
  );
}

export default ClientLocations;
