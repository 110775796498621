import { HeaderWithSideBar } from "./Components/headerWithSideBar/HeaderWithSideBar";
import React from "react";
import { Route, Routes, Navigate, Outlet, useNavigate } from "react-router-dom";
import { Login } from "./Components/Login";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import NotFound from "./Components/notFound/NotFound";
import {
  AdminAppTypeAccessLookUp,
  CommonAppTypeAccessLookUp,
  ReportingAppTypeAccessLookUp,
  UserAppsLookup,
  checkAppAccess,
  AllowedApps,
  OutputAllowedApps,
  CashTrackerLookup,
} from "./Components/admin/adminUtils";
import customComponentLoader from "./customComponentLoader";
import { Documents } from "./Components/documents/Documents";
import PeopleIcon from "@mui/icons-material/People";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import DomainIcon from "@mui/icons-material/Domain";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import Users from "./Components/admin/users/Users";
import AppRolePermission from "./Components/admin/appRolePermission/AppRolePermission";
import ClientLocationApp from "./Components/admin/clientLocation/app/ClientLocationApp";
import { ClientLocationDomain } from "./Components/admin/clientLocation/domain/ClientLocationDomain";
import RoleAppType from "./Components/admin/roleAppType/RoleAppType";
import ArticleIcon from "@mui/icons-material/Article";
import ActionTypeReporting from "./Components/reporting/actionTypeReporting/ActionTypeReporting";
import Genbill from "@genrecp/g2clientportal-genbill";
import {
  GetTokenAction,
  logout
} from "@genrecp/g2clientportal-client-portal/src/redux/auth/authActionCreator";
import UserActionLogReport from "./Components/reporting/userAccessLogReport/UserActionLogReport";
import { loginRequest } from "../authConfig";
import {
  handleErrorResponse,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { msalInstance } from "../index";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { DataSync } from "./Components/dataSync/DataSync";
import ClientPortal from "./ClientPortal";
import AdminDocument from "./Components/admin/documentation/AdminDocument";
import OracleDocucorp from "./Components/documents/OracleDocucorp";
import ExperienceReport from "./Components/delegatedApps/experienceReport/ExperienceReportWrapper";
import IncurredLosses from "./Components/delegatedApps/incurredLosses/IncurredLossesWrapper";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CashTrackerWrapper from "./Components/cashTrackerApp/CashTrackerWrapper";
import CashLog from "./Components/cashLog/CashLogWrapper";
import ReferenceTablesWrapper from "./Components/admin/delegatedIssuesAdmin/DelegatedIssuesAdminWrapper";
import { setMSALToken } from "../redux/slices/authSlice";
import DelegatedPolicyIssues from "./Components/delegatedPolicyIssues/components/DelegatedPolicyIssues";
import UserReporting from "./Components/admin/userReporting/UserReporting";

const LazyLossReporComp = React.lazy(() =>
  customComponentLoader(() => import("@genrecp/g2clientportal-loss-reporting"))
);

export const adminTabData: AllowedApps[] = [
  {
    to: "/admin/Users",
    pathNameForTabs: "/admin/Users",
    icon: <PeopleIcon />,
    primary: "Users",
    apptypeID: AdminAppTypeAccessLookUp.UserAdministration,
    name: "Users",
    comp: <Users />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/AppRolePermission",
    pathNameForTabs: "/admin/AppRolePermission",
    icon: <ManageAccountsIcon />,
    primary: "App Role Permission",
    apptypeID: AdminAppTypeAccessLookUp.AppRolePermissionAdministration,
    name: "AppRolePermission",
    comp: <AppRolePermission />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/ClientLocationApp",
    pathNameForTabs: "/admin/ClientLocationApp",
    icon: <PersonPinIcon />,
    primary: "Client Location App",
    apptypeID: AdminAppTypeAccessLookUp.ClientLocationAppAdministration,
    name: "ClientLocationApp",
    comp: <ClientLocationApp />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/ClientLocationDomain",
    pathNameForTabs: "/admin/ClientLocationDomain",
    icon: <DomainIcon />,
    primary: "Client Group Domain",
    apptypeID: AdminAppTypeAccessLookUp.ClientLocationDomainAdministration,
    name: "ClientLocationDomain",
    comp: <ClientLocationDomain />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/RoleAppType",
    pathNameForTabs: "/admin/RoleAppType",
    icon: <AppSettingsAltIcon />,
    primary: "Role App Type",
    apptypeID: AdminAppTypeAccessLookUp.RoleappAdministration,
    name: "RoleAppType",
    comp: <RoleAppType />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/Documentation",
    pathNameForTabs: "/admin/Documentation",
    icon: <ArticleIcon />,
    primary: "Documentation",
    apptypeID: AdminAppTypeAccessLookUp.Documentation,
    name: "Documentation",
    comp: <AdminDocument />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/cashTrackerAdmin",
    pathNameForTabs: "/admin/cashTrackerAdmin",
    icon: <ArticleIcon />,
    primary: "Cash Tracker Admin",
    apptypeID: AdminAppTypeAccessLookUp.ReferenceTables,
    name: "Delegated Issues Admin",
    comp: <ReferenceTablesWrapper />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
  {
    to: "/admin/userReporting",
    pathNameForTabs: "/admin/userReporting",
    icon: <ArticleIcon />,
    primary: "User Reporting",
    apptypeID: AdminAppTypeAccessLookUp.UserReporting,
    name: "User Reporting",
    comp: <UserReporting />,
    groupby: "ADMIN",
    groupTabName: "Admin Portal",
  },
];

const docTabData: AllowedApps[] = [
  {
    to: "/Applications",
    pathNameForTabs: "/Applications",
    icon: <ArticleIcon />,
    primary: "Applications",
    name: "Applications",
    apptypeID: CommonAppTypeAccessLookUp.Applications,
    comp: <Documents />,
    groupby: "DOCUMENTATION",
    groupTabName: "Documentation",
  },
  {
    to: "/Oracle-and-Docucorp-Libraries",
    pathNameForTabs: "/Oracle-and-Docucorp-Libraries",
    icon: <ArticleIcon />,
    primary: "Oracle and Docucorp Libraries",
    name: "Oracle-and-Docucorp-Libraries",
    apptypeID: CommonAppTypeAccessLookUp.OracleDocucorpLibraries,
    comp: <OracleDocucorp />,
    groupby: "DOCUMENTATION",
    groupTabName: "Documentation",
  },
  {
    to: "/Underwriting-Guide",
    pathNameForTabs: "/Underwriting-Guide",
    icon: <ArticleIcon />,
    primary: "Underwriting Guide",
    name: "Underwriting-Guide",
    apptypeID: CommonAppTypeAccessLookUp.UnderwritingGuide,
    comp: <Documents />,
    groupby: "DOCUMENTATION",
    groupTabName: "Documentation",
  },
  {
    to: "/Supplemental-Applications",
    pathNameForTabs: "/Supplemental-Applications",
    icon: <ArticleIcon />,
    primary: "Supplemental Applications",
    name: "Supplemental-Applications",
    apptypeID: CommonAppTypeAccessLookUp.SupplementalApplications,
    comp: <Documents />,
    groupby: "DOCUMENTATION",
    groupTabName: "Documentation",
  }
];

const reportingTabData: AllowedApps[] = [
  {
    to: "/UserActionTypeReporting",
    pathNameForTabs: "/UserActionTypeReporting",
    icon: <ArticleIcon />,
    primary: "Action Type",
    name: "Action Type",
    apptypeID: ReportingAppTypeAccessLookUp.ActionLogType,
    comp: <ActionTypeReporting />,
    topNav: false,
    groupby: "REPORTING",
    groupTabName: "Reporting",
  },
  {
    to: "/UserActionLogReporting",
    pathNameForTabs: "/UserActionLogReporting",
    icon: <ArticleIcon />,
    primary: "Action Log",
    name: "Action Log",
    apptypeID: ReportingAppTypeAccessLookUp.ActionLogDashboard,
    comp: <UserActionLogReport />,
    topNav: false,
    groupby: "REPORTING",
    groupTabName: "Reporting",
  },
];
const commonAppsdata: AllowedApps[] = [
  {
    to: "/brokerBilling",
    pathNameForTabs: "/brokerBilling",
    icon: <AccountBalanceIcon />,
    primary: "Broker Billing",
    name: "brokerBilling",
    apptypeID: CommonAppTypeAccessLookUp.BrokerBilling,
    groupby: "COMMON",
    comp: <Genbill />,
    groupTabName: "COMMON",
  },
  {
    to: "/dataSync",
    pathNameForTabs: "/dataSync",
    icon: <CloudUploadIcon />,
    primary: "Data Sync",
    name: "dataSync",
    apptypeID: CommonAppTypeAccessLookUp.DataSync,
    groupby: "COMMON",
    comp: <DataSync />,
    groupTabName: "COMMON",
  },
  {
    to: "/lossReporting",
    pathNameForTabs: "/lossReporting",
    icon: <RequestQuoteIcon />,
    primary: "Loss Reporting",
    name: "lossReporting",
    apptypeID: CommonAppTypeAccessLookUp.LossReporting,
    groupby: "COMMON",
    comp: (
      <React.Suspense fallback="Loading...">
        <LazyLossReporComp />
      </React.Suspense>
    ),
    groupTabName: "COMMON",
  },
  {
    to: "/delegatedIssues",
    pathNameForTabs: "/delegatedIssues",
    icon: <RequestQuoteIcon />,
    primary: "Delegated Policy Issues",
    name: "DelegatedIssues",
    apptypeID: CommonAppTypeAccessLookUp.DelegatedIssues,
    groupby: "COMMON",
    comp: <DelegatedPolicyIssues />,
    groupTabName: "COMMON",
  }
];

const delegatedTabData: AllowedApps[] = [
  {
    to: "/experienceReport",
    pathNameForTabs: "/experienceReport",
    icon: <RequestQuoteIcon />,
    primary: "Experience Report",
    name: "experienceReport",
    apptypeID: UserAppsLookup.DelegatedExperienceReport,
    comp: <ExperienceReport />,
    topNav: false,
    groupby: "DELEGATED",
    groupTabName: "Delegated Reporting",
  },
  {
    to: "/incurredLosses",
    pathNameForTabs: "/incurredLosses",
    icon: <RequestQuoteIcon />,
    primary: "Incurred Losses",
    name: "incurredLosses",
    apptypeID: UserAppsLookup.DelegatedIncurredLossReport,
    comp: <IncurredLosses />,
    topNav: false,
    groupby: "DELEGATED",
    groupTabName: "Delegated Reporting",
  },
];


const cashTrackerTabData: AllowedApps[] = [
  {
    apptypeID: CashTrackerLookup.CashTracker,
    comp: <CashTrackerWrapper />,
    groupby: "CASH_TRACKER",
    icon: <RequestQuoteIcon />,
    groupTabName: "Cash Tracker Apps",
    name: "cashTracker",
    pathNameForTabs: "/cashTracker",
    to: "/cashTracker",
    primary: "Cash Tracker",
  },
  {
    apptypeID: CashTrackerLookup.CashLog,
    comp: <CashLog />,
    groupby: "CASH_TRACKER",
    icon: <RequestQuoteIcon />,
    groupTabName: "Cash Tracker Apps",
    name: "Cash Log",
    pathNameForTabs: "/cashLog",
    to: "/cashLog",
    primary: "Cash Log",
  }
];


const allApps: AllowedApps[] = [
  ...adminTabData,
  ...commonAppsdata,
  ...docTabData,
  ...reportingTabData,
  ...delegatedTabData,
  ...cashTrackerTabData
];

function AppWithRouter() {
  const msalBypass: boolean =
    process.env.REACT_APP_MSAL_BYPASS?.toLowerCase() === "true";
  const $dispatch = useDispatch<AppDispatch>();
  let isAuthenticated = useIsAuthenticated();
  const appRolePermissions = useSelector(
    (state: RootState) => state.auth.currentUser?.appRolePermissions
  );
  const expirationTime = useSelector(
    (state: RootState) => state.auth.currentUser?.exp!
  );
  const msalExpirationTime = useSelector(
    (state: RootState) => state.auth.msalUser?.idTokenClaims?.exp!
  );
  const navigate = useNavigate();
  const [allowedApps, setAllowedApps] = React.useState<OutputAllowedApps>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const account = msalInstance.getActiveAccount();
  const { enqueueSnackbar } = useRibbonSnackbar();
  const token = useSelector((state: RootState) => state?.auth?.token);
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );
  const isExternalUser = isExternal && JSON.parse(isExternal.toLowerCase());

  const [logoutCheck, setLogoutCheck] = React.useState<boolean>(
    expirationTime * 1000 < Date.now()
  );

  const [logoutMsalCheck, setLogoutMsalCheck] = React.useState<boolean>(
    msalExpirationTime * 1000 < Date.now()
  );

  const [redirectTo, setRedirectTo] = React.useState("");

  React.useEffect(() => {
    if (msalBypass) {
      isAuthenticated = true;
      $dispatch(
        GetTokenAction(
          navigate,
          "",
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJkNWQ5ZGEyNS04MTlkLTQ0NWYtOTczOC0xODNiMWQ1YTQyNzEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZGE1NWExN2MtOTA2YS00ZWRhLTgyMzktMThmZWNiNjAyOTY1L3YyLjAiLCJpYXQiOjE2OTY4NTA3MTYsIm5iZiI6MTY5Njg1MDcxNiwiZXhwIjoxNjk2ODU1NzExLCJhaW8iOiJBV1FBbS84VUFBQUEyUk9zdzAvNGxHa3lUQ1NXbGdJQWQwQStDc1lycFdEYzZXR1BJTHJ1WnJGdS9ObW9hR3JyRjUyQ1FoMldIYnlRL0Z0a1p3Yk9NOUVPWG80Q0JQWjk0MkU1RENDY3dieHgwTFVLbVM1TFFWOG1lSHoxY1NzOUhHcm1xUWdHOGxnViIsImF6cCI6IjdjNjYyNmZiLWE5NjAtNDlmYi05Zjg0LTU2NDM1MzE5YjdjNiIsImF6cGFjciI6IjAiLCJuYW1lIjoiUWFzaW0gU2lkZGlxdWUgKENvbnN1bHRhbnQpIiwib2lkIjoiZmI3YzJiNWItYWEzMC00YmUzLWE5OGYtZDNlYWY5NjViYTJkIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiUWFzaW0uU2lkZGlxdWVAR0VORVJBTFNUQVIuQ09NIiwicmgiOiIwLkFVY0FmS0ZWMm1xUTJrNkNPUmoteTJBcFpTWGEyZFdkZ1Y5RWx6Z1lPeDFhUW5GSEFNcy4iLCJzY3AiOiJmaWxlcy5yZWFkIiwic3ViIjoiQkFUVWFpaGROTUtXWVo2Zk4xeThLVDhSdFRzOHh1X214TWxMYzFSTktjSSIsInRpZCI6ImRhNTVhMTdjLTkwNmEtNGVkYS04MjM5LTE4ZmVjYjYwMjk2NSIsInV0aSI6IlRCbDVCWnpnVjBPbUZUdjl4OFZHQUEiLCJ2ZXIiOiIyLjAifQ.CEfNbZdrvDZidTg7uzPN3owfQ5JYdyFWV-EBi-HYYIi767nVXkImhvMDEQUTIUNL5K28OWKD4P6nLZGPT0-R6ueGoXqV3o5c6LmeLXQasEsvfohVyaRTFfVkofaa4yyToff2nA6rxTXpXgM6LV7LSb24aO6uKjGoDgVyhstdZctmcR0I8oa0dpd5MqZ6dT1DVHbUMJTismoaV2gZ_1oxSe1BeCBqfGMTbqnA--Lcq6LBsTlL-wt9LB7iDDmSgFPbvrdl3Dp0xyJZI7wO3XoyDTaJkKXGyhyTaGkv6Eo3yR3lhcrCOTff9B6Z-stERvo2TAUiBCaWWST1uwwOxl4QrQ",
          "/"
        )
      );
    }
  }, [msalBypass]);

  //Login request to backend JWT
  React.useEffect(() => {
    const currentRoute = window.location.pathname;
    setRedirectTo(currentRoute);
    if (isAuthenticated && !token && !msalBypass) {
      msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account: account!,
        })
        .then((response) => {
          $dispatch(
            GetTokenAction(
              navigate,
              account,
              response?.accessToken,
              currentRoute
            )
          );
        })
        .catch((e) => {
          console.log("Promise Failed ", e);
          const modalErrorMessages = handleErrorResponse(e);
          enqueueSnackbar(`Error! Login Failed`, {
            variant: "error",
            errorObject: modalErrorMessages,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  //Refresh MSAL Token
  React.useEffect(()=>{
    if(isAuthenticated){
    setInterval(()=> {
          msalInstance
            .acquireTokenSilent({
              ...loginRequest,
              account: account!,
              forceRefresh: true,
            })
            .then((response) => {
              $dispatch(setMSALToken(response));
            })
            .catch((e) => {
              console.log("Promise Failed ", e);
              const modalErrorMessages = handleErrorResponse(e);
              enqueueSnackbar(`Error! Login Failed`, {
                variant: "error",
                errorObject: modalErrorMessages,
              });
              logout();
            });
      }, 60*60*1000);
    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isAuthenticated])

  //Token expiration time check on msal token and JWT
  React.useEffect(() => {
    setLogoutCheck(expirationTime * 1000 < Date.now());
    setLogoutMsalCheck(msalExpirationTime * 1000 < Date.now());
    if (isAuthenticated && token) {
      if (logoutCheck && logoutMsalCheck) {
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, logoutCheck, logoutMsalCheck]);

  React.useEffect(() => {
    if (appRolePermissions) {
      const allowedFilterApps: OutputAllowedApps = checkAppAccess(
        allApps,
        isExternalUser,
        appRolePermissions
      );
      setAllowedApps(allowedFilterApps);

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRolePermissions]);

  const PrivateWrapper = ({ currentUser }: any) => {
    if (msalBypass) {
      return currentUser ? (
        <HeaderWithSideBar allowedApps={allowedApps}>
          <Outlet />
        </HeaderWithSideBar>
      ) : null;
    }
    if (!isAuthenticated && !token && redirectTo !== "/") {
      return currentUser ? (
        <AuthenticatedTemplate>
          <HeaderWithSideBar allowedApps={allowedApps}>
            <Outlet />
          </HeaderWithSideBar>
        </AuthenticatedTemplate>
      ) : (
        <Navigate to="/" />
      );
    }
    return currentUser ? (
      <AuthenticatedTemplate>
        <HeaderWithSideBar allowedApps={allowedApps}>
          <Outlet />
        </HeaderWithSideBar>
      </AuthenticatedTemplate>
    ) : null;
  };

  function Check() {
    return !isAuthenticated && !token ? (
      <Login />
    ) : isAuthenticated && !token ? (
      <Login />
    ) : !isAuthenticated && token ? (
      <Login />
    ) : isAuthenticated && token && redirectTo === "/" ? (
      <Navigate to="/clientportal" />
    ) : (
      <Navigate to={redirectTo} />
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Check />} />
        <Route element={<PrivateWrapper currentUser={isAuthenticated} />}>
          <Route
            path="clientPortal"
            element={<ClientPortal allowedApps={allowedApps} />}
          />
          {Object.values(allowedApps)
            .flat()
            .map((item, idx) => (
              <Route path={item?.to} element={item?.comp} key={idx} />
            ))}
          {!loading ? <Route path="*" element={<NotFound />} /> : null}
        </Route>
        {
          !appRolePermissions && 
          <Route path="*" element={<Navigate to="/"/>} />
        }
      </Routes>
    </>
  );
}

export default AppWithRouter;
