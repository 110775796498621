import React, { Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ICellRendererParams, ColDef, GridReadyEvent } from "ag-grid-community";
import { commonAxios } from "../../../../services/axios";
import {
  CustomDrawer,
  CustomSnackbar,
  DetailSpan,
  handleErrorResponse,
  ModalErrors,
} from "@genrecp/g2clientportal-common";
import { Grid, Typography } from "@mui/material";
import { User } from "./UserReporting";

const defaultColDef: ColDef = {
  cellClass: "cell-wrap-text",
  cellStyle: { whiteSpace: "normal" },
  sortable: true,
  resizable: true,
  floatingFilter: true,
  flex: 1,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
};

function AppRoles(props: ICellRendererParams<User>) {
  const [open, setOpen] = React.useState(false);
  const userID = props.data?.userID;
  const onClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const appTypeRolesColumnDefs = [
    {
      field: "appTypeName",
      colId: "appTypeName",
      headerName: "App Type",
      filter: "agTextColumnFilter",
    },
    {
      field: "roleName",
      colId: "roleName",
      headerName: "Role",
      filter: "agTextColumnFilter",
    },
    {
      field: "permissionLevelName",
      colId: "permissionLevelName",
      headerName: "Permission",
      filter: "agTextColumnFilter",
    },
  ];

  const getData = (params: GridReadyEvent) => {
    commonAxios
      .get(`UserReporting/GetUserData/${userID}/${props.data?.external}`)
      .then((res) => {
        const { appTypeRoles } = res?.data || {};
        params.api.setRowData(appTypeRoles || []);
      })
      .catch((e) => {
        params.api.setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const onGridReady = (params: GridReadyEvent) => {
    if (userID) getData(params);
  };

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <DetailSpan onClick={handleClickOpen}>App Roles</DetailSpan>
      <CustomDrawer
        open={open}
        title="App Roles"
        onClose={onClose}
        drawerWidth={window.innerWidth - 100}
        setOpen={setOpen}
        drawerPadding={0}
      >
        <Grid container spacing={2}>
          <Grid
            item
            md={4}
            mb={2}
            display={"flex"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <Typography variant="labelMedium">Login ID:</Typography>
            <Typography variant="titleMedium">
              {props.data?.userLoginID}
            </Typography>
          </Grid>
          <Grid item md={4} display={"flex"} justifyContent={"center"} gap={1}>
            <Typography variant="labelMedium">Name: </Typography>
            <Typography variant="titleMedium">
              {`${props.data?.firstName} ${props.data?.lastName}`}
            </Typography>
          </Grid>
          <Grid item md={4} display={"flex"} justifyContent={"end"} gap={1}>
            <Typography variant="labelMedium">Email: </Typography>
            <Typography variant="titleMedium">
              {props.data?.emailAddress}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <div
              style={{ width: "100%", height: window.innerHeight - 250 }}
              className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
            >
              <AgGridReact
                rowHeight={29}
                headerHeight={32}
                defaultColDef={defaultColDef}
                columnDefs={appTypeRolesColumnDefs}
                pagination={true}
                cacheBlockSize={10}
                paginationPageSize={10}
                animateRows={true}
                onGridReady={onGridReady}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </CustomDrawer>
    </Fragment>
  );
}

export default AppRoles;
